<template>
  <div style="width: 33%">
    <b-form-group label="Groups">
      <b-form-tags
        v-model="userGroupData"
        no-outer-focus
      >
        <template v-slot="{ tags, disabled, addTag, removeTag }">
          <ul
            v-if="tags.length > 0"
            class="list-inline d-inline-block mb-1"
          >
            <li
              v-for="tag in tags"
              :key="tag"

              class="list-inline-item"
            >
              <b-form-tag
                :title="tag"
                :disabled="disabled"
                variant="primary"
                @remove="onRemoveClick({tag, removeTag})"
              >
                {{ tag }}
              </b-form-tag>
            </li>
          </ul>

          <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            menu-class="w-100"
          >
            <template v-slot:button-content>
              <b-icon icon="tag-fill" /> Choose tags
            </template>
            <div id="forceScroll">
              <b-dropdown-form @submit.stop.prevent="() => {}">
                <b-form-group
                  label-for="tag-search-input"
                  label="Search tags"
                  label-cols-md="auto"
                  class="mb-0"
                  label-size="sm"
                  :description="searchDesc"
                  :disabled="disabled"
                >
                  <b-form-input
                    id="tag-search-input"
                    v-model="search"
                    type="search"
                    size="sm"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-dropdown-form>

              <b-dropdown-divider />
              <b-dropdown-item
                v-for="option in availableOptions"
                :key="option"
                @click="onOptionClick({ option, addTag })"
              >
                {{ option }}
              </b-dropdown-item>
              <b-dropdown-text v-if="availableOptions.length === 0">
                There are no tags available to select
              </b-dropdown-text>
            </div>
          </b-dropdown>
        </template>
      </b-form-tags>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormTags, BFormGroup, BDropdown, BDropdownForm, BFormInput, BDropdownDivider, BDropdownItem, BFormTag, BDropdownText,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BFormTags,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BDropdownDivider,
    BDropdownItem,
    BFormTag,
    BDropdownText,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    groupData: {
      type: Object,
      required: true,
    },
    userGroupData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      const { criteria } = this

      // Filter out already selected options
      const groupData = this.groupData.filter(opt => this.userGroupData.indexOf(opt) === -1)
      if (criteria) {
        // Show only options that match criteria
        return groupData.filter(opt => opt.toLowerCase().indexOf(criteria) > -1)
      }

      // Show all options available
      return groupData
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria'
      }
      return ''
    },
  },
  methods: {
    onOptionClick({ option, addTag }) {
      addTag(option)
      this.search = ''
      store.dispatch('app-user/addGroupToUser', { userName: router.currentRoute.params.id, groupName: option })
        .then(response => { console.log(response) })
        .catch(error => {
          console.log(error)
        })
    },
    onRemoveClick({ tag, removeTag }) {
      removeTag(tag)
      store.dispatch('app-user/removeGroupFromUser', { userName: router.currentRoute.params.id, groupName: tag })
        .then(response => { console.log(response) })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
#forceScroll{
   max-height:500px;
   overflow:auto;
}
</style>
